import _defineProperty from "/var/lib/jenkins/workspace/edu-main-ui-test/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "application"
};
var _hoisted_2 = {
  class: "application-top"
};
var _hoisted_3 = {
  class: "application-top-logo"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "overflow-auto"
};
var _hoisted_6 = ["onClick", "onMouseenter", "onMouseleave"];
var _hoisted_7 = {
  class: "application-top-tabs-img"
};
var _hoisted_8 = ["src"];
var _hoisted_9 = ["src"];
var _hoisted_10 = {
  class: "boxiao-box"
};
var _hoisted_11 = {
  class: "boxiao-top"
};
var _hoisted_12 = {
  class: "school-seletc"
};
var _hoisted_13 = ["src"];
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { MessagePlugin } from 'tdesign-vue-next';
import { routeObj } from "@/const/router";
import { useRoute, useRouter } from "vue-router";
import { getStore } from "@/util/store";
import { env_name, toLogin } from "@/util/util";
import navLeft from "@/components/navLeft";

//   import resourcePreview from '@/views/sourcePreview/resourcePreview'
import { getAppInfo, getAppAlias } from "@/api/home.js";
import { getAuthSchoolList, saveCurrentSchool } from "@/api/boxiao.js";
import nopermission from "@/components/nopermission";
var DEFAULT_TITLE = "慧学慧研";
export default {
  __name: 'boxiao',
  setup: function setup(__props) {
    var _route$query;
    var route = useRoute();
    var router = useRouter();
    var visibleLeftBar = ref(true);
    var Iframe = ref(null);
    var bxUrl = ref("");
    var mouseenter = function mouseenter(e, url) {
      e.target.className.indexOf("active") === -1 && (e.target.firstChild.src = require("@/assets/nav/".concat(url, "-active.svg")));
    };
    var mouseleave = function mouseleave(e, url) {
      e.target.className.indexOf("active") === -1 && (e.target.firstChild.src = require("@/assets/nav/".concat(url, ".svg")));
    };
    var access_token = getStore({
      name: "access_token"
    }); // 传递token到子应用
    var accessInfo = getStore({
      name: "accessInfo"
    }); // 传递accessInfo到子应用
    var appAllList = getStore({
      name: "appAllList"
    }); // 获取所有应用列表

    var navClick = function navClick(item, index) {
      visibleLeftBar.value = true;
      pageTitle.value = item.name;
      bxUrl = getUrl(item.url);
      if (item.url && item.url !== route.query[route.name] && (route.query[route.name] !== undefined || index !== 0)) {
        router.push({
          path: route.path,
          query: _defineProperty({}, route.name, item.url)
        });
      }
    };
    var overtime = window.overtime;
    var origin = window.location.origin;
    var queryObj = ref(null);
    if ((_route$query = route.query) !== null && _route$query !== void 0 && _route$query.hideLeftBar) {
      visibleLeftBar.value = false;
    }
    if (window.location.search) {
      var arr = window.location.search.slice(1).split("&");
      if (arr.length) {
        var obj = {};
        arr.forEach(function (item) {
          // console.log(item)
          var itArr = item.split("=");
          obj[itArr[0]] = itArr[1];
        });
        queryObj.value = obj;
        // console.log(queryObj.value)
      }
    }
    var jump = function jump(location, query) {
      var currentWindow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      queryObj.value = query;
      if (location.indexOf("/courseDetail") > -1 || location.indexOf("/curriculumDetail") > -1 || location.indexOf("/newCurriculumDetail") > -1 || location.indexOf("/practiceDetail") > -1 || location.indexOf("/articleDetail") > -1 || location.indexOf("/resourceDetail") > -1 || location.indexOf("/newsDetail") > -1 || location.indexOf("activityDetail") > -1 || location.indexOf("researchTrain") > -1 || location.indexOf("editResource") > -1) {
        var url = origin + location;
        currentWindow ? window.location.href = url : window.open(url);
      } else {
        router.push(location);
      }
    };

    // 获取app信息 渲染左侧头部图片
    var icon = ref("");
    getAppAlias(route.name).then(function (res) {
      if (res.data.code === 0) {
        var _res$data$data;
        icon.value = (_res$data$data = res.data.data) === null || _res$data$data === void 0 || (_res$data$data = _res$data$data.app) === null || _res$data$data === void 0 ? void 0 : _res$data$data.icon;
      }
    });

    // 获取博校应用地址
    var getUrl = function getUrl(checkUrl) {
      var _routeObjRef$value$ro;
      var surfix = checkUrl || ((_routeObjRef$value$ro = routeObjRef.value[route.name].navs[0]) === null || _routeObjRef$value$ro === void 0 ? void 0 : _routeObjRef$value$ro.url);
      var curmenu = routeObjRef.value[route.name].navs.find(function (item) {
        return item.url === surfix;
      });
      surfix.split("/").length > 1 ? surfix = surfix.slice(1) : '';
      var url = '';
      var appUrl = routeObj[route.name].url;
      if (env_name === 'dev-edu' || env_name === '') {
        appUrl = appUrl.replace('huixuecloud.cn', 'zhixuehuijiao.cn');
      }
      if (route.name === 'boxiaoAchievementManage') {
        url = "//".concat(env_name).concat(appUrl, "?bayToken=").concat(access_token, "&menuType=").concat(surfix);
      } else if (route.name === 'boxiaoCurriculaManage') {
        url = "//".concat(env_name).concat(appUrl).concat(surfix, "?bayToken=").concat(access_token);
      } else if (route.name === 'boxiaoArrangingManage' || route.name === 'boxiaoQapaperManage' || route.name === 'boxiaoBuildingRoom' || route.name === 'boxiaoSchoolArranging') {
        url = "//".concat(env_name).concat(appUrl).concat(curmenu.url, "?bayToken=").concat(access_token).concat(curmenu.tag ? "&menuType=".concat(curmenu.tag) : '');
      } else if (route.name === 'boxiaoDataDev') {
        url = "//".concat(env_name).concat(appUrl).concat(surfix, "?bayToken=").concat(access_token, "&menuType=dataDevelop").concat(curmenu.tag ? "&hideType=".concat(curmenu.tag) : '');
      } else if (route.name === 'boxiaoElectivesManage') {
        url = "//".concat(env_name).concat(appUrl).concat(curmenu.url, "?bayToken=").concat(access_token, "&hideType=").concat(curmenu.tag ? 'show' : 'hide').concat(curmenu.tag ? "&menuType=".concat(curmenu.tag) : '');
      } else if (['boxiaoEvaTeachManage', 'boxiaoEvaTeachReport', 'boxiaoEvaTeachStudent', 'boxiaoSendRecieveFile', 'boxiaoSendRecieveFileUser', 'boxiaoListenEvalCourseManage', 'boxiaoListenEvalCourse', 'boxiaoWorkflowManage', 'boxiaoWorkflowExamine', 'boxiaoStuAskForLeaveManage', 'boxiaoStuAskForLeaveTeacher', 'boxiaoStuAskForLeaveStu', 'boxiaoKnowledgeAnalyse', 'boxiaoTeacherAttendanceManage', 'boxiaoTeacherAttendance', 'boxiaoStudentPrizeManage', 'boxiaoStudentPrize', 'boxiaoFiveEvaluationManage', 'boxiaoExamMattersManage', 'boxiaoMeetApointmentManage', 'boxiaoMeetApointment'].includes(route.name)) {
        url = "//".concat(env_name).concat(appUrl).concat(surfix, "?bayToken=").concat(access_token);
      } else {
        url = "//".concat(env_name).concat(appUrl, "?bayToken=").concat(access_token);
      }
      return url;
    };

    // 右侧标题
    var pageTitle = ref("");
    var setTitle = function setTitle() {
      var _navs = routeObjRef.value[route.name].navs;
      var curmenu = route.query[route.name];
      pageTitle.value = curmenu ? _navs.find(function (item) {
        return item.url === curmenu;
      }).name : _navs[0].name;
    };

    // 获取页面信息
    var getPageInfo = function getPageInfo() {
      isFresh.value = true;
      bxUrl = getUrl(route.query[route.name]);
      setTitle();
    };

    // 子应用菜单栏动态管理
    var isFresh = ref(false);
    var routeObjRef = ref();
    routeObjRef.value = JSON.parse(JSON.stringify(routeObj));
    var getAppInfoMtd = function getAppInfoMtd() {
      var hasPermissionFst = ['boxiaoQapaperManage', 'boxiaoElectivesManage', 'boxiaoArrangingManage'];
      var hasPermissionSnd = ['boxiaoEvaTeachManage', 'boxiaoEvaTeachReport', 'boxiaoSendRecieveFile', 'boxiaoStuAskForLeaveManage', 'boxiaoStuAskForLeaveTeacher', 'boxiaoKnowledgeAnalyse', 'boxiaoTeacherAttendanceManage', 'boxiaoStudentPrizeManage', 'boxiaoFiveEvaluationManage'];
      if (hasPermissionFst.includes(route.name)) {
        getAppInfo(route.name).then(function (res) {
          if (res.data.code === 0) {
            if (route.name === 'boxiaoArrangingManage') {
              var needDel = !res.data.data.auth.some(function (e) {
                return e.permission === 'NTT10';
              });
              if (needDel) {
                routeObjRef.value[route.name].navs = routeObjRef.value[route.name].navs.filter(function (i) {
                  return i.url !== '/zonetable';
                });
              }
              if (needDel && route.query[route.name] === '/zonetable') {
                MessagePlugin.error('您没有该菜单权限');
                router.go(-1);
              }
            } else {
              var resData = res.data.data.auth.map(function (i) {
                return i.permission;
              });
              routeObjRef.value[route.name].navs = routeObjRef.value[route.name].navs.filter(function (i) {
                return resData.includes(i.permi);
              });
            }
            getPageInfo();
          }
        });
      } else if (hasPermissionSnd.includes(route.name)) {
        getAppInfo(route.name).then(function (res) {
          if (res.data.code === 0) {
            var resData = res.data.data.auth.map(function (i) {
              return i.permission;
            });
            routeObjRef.value[route.name].navs = routeObjRef.value[route.name].navs.filter(function (i) {
              return !i.permi || resData.includes(i.permi);
            });
            getPageInfo();
          }
        });
      } else {
        // 其它不需要控制菜单权限的应用
        getPageInfo();
      }
    };
    if (accessInfo.accountType !== 0) {
      getAppInfoMtd();
    } else {
      getPageInfo();
    }

    // 动态修改导航名称
    var findObj = appAllList.find(function (item) {
      return route.name === item.alias;
    });
    if (findObj) {
      document.title = findObj.name;
    } else {
      document.title = DEFAULT_TITLE;
    }
    var schoolList = ref(null);
    var currentSchoolId = ref(null);
    // 获取学校列表
    var getSchoolList = function getSchoolList() {
      getAuthSchoolList().then(function (res) {
        schoolList.value = res.data.data;
        var currentSchool = schoolList.value.filter(function (item) {
          return item.isCurrentSchool;
        });
        if (currentSchool && currentSchool.length) {
          currentSchoolId.value = currentSchool[0].schoolId;
        } else {
          currentSchoolId.value = schoolList.value[0].schoolId;
        }
        changeSChool(currentSchoolId.value);
      });
    };
    getSchoolList();
    // 切换学校
    var changeSChool = function changeSChool(id) {
      saveCurrentSchool(id).then(function (res) {
        Iframe.value.src = bxUrl + "?schoolId=".concat(id);
      });
    };
    var _removeDownloadsTag = function removeDownloadsTag() {
      if (Iframe.value && Iframe.value.sandbox) {
        Iframe.value.sandbox.remove('allow-downloads');
      } else {
        setTimeout(function () {
          _removeDownloadsTag();
        }, 500);
      }
    };

    // 监听路由变化
    watch(function () {
      return route.name;
    }, function (value) {
      var findObj = appAllList.find(function (item) {
        return value === item.alias;
      });
      if (findObj) {
        document.title = findObj.name;
      } else {
        document.title = DEFAULT_TITLE;
      }
    });
    onMounted(function () {
      window.addEventListener('message', function (ev) {
        if (ev.origin.includes('')) {
          var _ev$data = ev.data,
            type = _ev$data.type,
            fileName = _ev$data.fileName,
            fileUrl = _ev$data.fileUrl;
          if (type === 'download') {
            window.JSAndroid && window.JSAndroid.downLoadSource(JSON.stringify({
              title: fileName,
              url: fileUrl
            }));
          } else if (type === 'tokenunfresh') {
            toLogin();
          }
        }
      });
      // 平板端去除iframe下载
      if (window.JSAndroid) {
        _removeDownloadsTag();
      }
    });
    onBeforeUnmount(function () {
      window.removeEventListener('message');
      document.title = DEFAULT_TITLE;
    });
    return function (_ctx, _cache) {
      var _component_t_option = _resolveComponent("t-option");
      var _component_t_select = _resolveComponent("t-select");
      return isFresh.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_unref(navLeft), null, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
            src: icon.value,
            alt: ""
          }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routeObjRef.value[_ctx.$route.name].navs, function (item, index) {
            var _ctx$$route$query$_ct, _ctx$$route$query$_ct2;
            return _openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["application-top-tabs", {
                active: ((_ctx$$route$query$_ct = _ctx.$route.query[_ctx.$route.name]) === null || _ctx$$route$query$_ct === void 0 ? void 0 : _ctx$$route$query$_ct.indexOf(item.url)) > -1 || _ctx.$route.query[_ctx.$route.name] === undefined && index === 0
              }]),
              key: index,
              onClick: function onClick($event) {
                return navClick(item, index);
              },
              onMouseenter: function onMouseenter(e) {
                return mouseenter(e, item.img);
              },
              onMouseleave: function onMouseleave(e) {
                return mouseleave(e, item.img);
              }
            }, [_createElementVNode("div", _hoisted_7, [((_ctx$$route$query$_ct2 = _ctx.$route.query[_ctx.$route.name]) === null || _ctx$$route$query$_ct2 === void 0 ? void 0 : _ctx$$route$query$_ct2.indexOf(item.url)) > -1 || _ctx.$route.query[_ctx.$route.name] === undefined && index === 0 ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: item.img ? require("@/assets/nav/".concat(item.img, "-active.svg")) : ''
            }, null, 8, _hoisted_8)) : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: item.img ? require("@/assets/nav/".concat(item.img, ".svg")) : ''
            }, null, 8, _hoisted_9))]), _createElementVNode("div", null, _toDisplayString(item.name), 1)], 42, _hoisted_6);
          }), 128))])])];
        }),
        _: 1
      }, 512), [[_vShow, visibleLeftBar.value]]), _createElementVNode("div", _hoisted_10, [routeObjRef.value[_ctx.$route.name].navs.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", _hoisted_11, [_createElementVNode("span", null, _toDisplayString(pageTitle.value), 1), _createElementVNode("div", _hoisted_12, [_createVNode(_component_t_select, {
        autoWidth: "",
        borderless: "",
        modelValue: currentSchoolId.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return currentSchoolId.value = $event;
        }),
        onChange: changeSChool
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(schoolList.value, function (item) {
            return _openBlock(), _createBlock(_component_t_option, {
              key: item.schoolId,
              value: item.schoolId,
              label: item.schoolName
            }, null, 8, ["value", "label"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue"])])]), _createElementVNode("iframe", {
        ref_key: "Iframe",
        ref: Iframe,
        style: {
          "flex": "1"
        },
        src: _unref(bxUrl),
        sandbox: "allow-scripts allow-same-origin allow-top-navigation allow-popups allow-downloads"
      }, null, 8, _hoisted_13)], 64)) : (_openBlock(), _createBlock(_unref(nopermission), {
        key: 1
      }))])])) : _createCommentVNode("", true);
    };
  }
};