import { request } from "hxhy-axios"
import { apiList as api } from "./apiList"

// 获取当前用户有数据权限的学校列表
export function getAuthSchoolList() {
  return request({
    url: api.boxiao.getAuthSchoolList,
    method: "get",
  })
}

export function saveCurrentSchool(params) {
  return request({
    url: api.boxiao.saveCurrentSchool + params,
    method: "get",
  })
}
