import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "application"
};
var _hoisted_2 = ["src"];
import { ref, onMounted } from "vue";
import { MessagePlugin } from 'tdesign-vue-next';
import { getStore } from "@/util/store";
import { env_name } from "@/util/util";
export default {
  __name: 'boxiaoShare',
  setup: function setup(__props) {
    var Iframe = ref(null);
    var bxUrl = ref("");
    var access_token = getStore({
      name: "access_token"
    });
    var queryObj = ref(null);
    // 子应用菜单栏动态管理
    var isFresh = ref(false);
    if (window.location.search) {
      var arr = window.location.search.slice(1).split("&");
      if (arr.length) {
        var obj = {};
        arr.forEach(function (item) {
          var itArr = item.split("=");
          obj[itArr[0]] = itArr[1];
        });
        queryObj.value = obj;
      }
    }

    // 获取页面信息
    var getPageInfo = function getPageInfo() {
      if (queryObj.value) {
        isFresh.value = true;
        var baseShareUrl = 'edudcs.huixuecloud.cn';
        if (env_name === 'dev-edu' || env_name === '') {
          baseShareUrl = baseShareUrl.replace('huixuecloud.cn', 'zhixuehuijiao.cn');
        }
        var _queryObj$value = queryObj.value,
          type = _queryObj$value.type,
          shareType = _queryObj$value.shareType,
          questionId = _queryObj$value.questionId;
        if (shareType === 'url' && type && questionId) {
          bxUrl = "//".concat(env_name).concat(baseShareUrl, "/fe-pc/b/fe_pc_filling_form/").concat(type, "/").concat(questionId, "?bayToken=").concat(access_token);
        } else if (shareType === 'img' && questionId) {
          bxUrl = "//".concat(env_name).concat(baseShareUrl, "/fe-pc/b/fe_h5_filling_form?questionId=").concat(questionId, "&bayToken=").concat(access_token);
        } else {
          MessagePlugin.error("缺少必要参数");
        }
      }
    };
    onMounted(function () {
      getPageInfo();
    });
    return function (_ctx, _cache) {
      return isFresh.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("iframe", {
        ref_key: "Iframe",
        ref: Iframe,
        style: {
          "flex": "1"
        },
        src: _unref(bxUrl),
        sandbox: "allow-scripts allow-same-origin allow-top-navigation allow-popups allow-downloads"
      }, null, 8, _hoisted_2)])) : _createCommentVNode("", true);
    };
  }
};