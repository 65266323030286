import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "ivu-exception"
};
var _hoisted_2 = {
  class: "ivu-exception-content"
};
var _hoisted_3 = {
  class: "ivu-exception-content-actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "ivu-exception-img"
  }, [_createElementVNode("div", {
    class: "ivu-exception-img-element"
  })], -1)), _createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "ivu-exception-content-desc"
  }, "抱歉，您暂无权限访问该页面", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handlerRoute && $options.handlerRoute.apply($options, arguments);
    }),
    class: "ivu-btn ivu-btn-primary ivu-btn-large"
  }, _cache[1] || (_cache[1] = [_createElementVNode("span", null, "返回首页", -1)]))])])]);
}