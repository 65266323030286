import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import router from "@/router";
export default {
  name: "nopermission",
  methods: {
    handlerRoute: function handlerRoute() {
      router.replace('/');
    }
  }
};